import axios from 'axios';
import { useAuthStore } from '@/stores/authStore';
// import { useRouter } from 'vue-router';
import { showMessage } from '@/utils/index';

const apiClient = axios.create({
    baseURL: '/api',
    headers: {
        'Content-Type': 'application/json',
    },
});

const BASE64_SIZE_LIMIT = 2 * 1024 * 1024; // 2MB size limit (adjust as needed)

apiClient.interceptors.request.use(
    (config) => {
        const authStore = useAuthStore();
        authStore.loadUser(); // Ensure user data is loaded from local storage

        if (authStore.isAuthenticated) {
            config.headers.Authorization = `${authStore.token}`;
        }

        // Check for large base64 strings in the request body
        if (config.data && typeof config.data === 'object') {
            for (const key in config.data) {
                if (config.data.hasOwnProperty(key) && typeof config.data[key] === 'string' && config.data[key].startsWith('data:image')) {
                    const base64String = config.data[key].split(',')[1]; // Extract the base64 part
                    const byteLength = base64String.length * (3 / 4); // Calculate the byte size of the base64 string

                    if (byteLength > BASE64_SIZE_LIMIT) {
                        showMessage(
                            `Image is too large to upload. Please ensure it is less than ${BASE64_SIZE_LIMIT / 1024 / 1024} MB.`,
                            'danger',
                            'top-end',
                            true,
                            '',
                            3000
                        );
                        return Promise.reject(new Error('Image size exceeds the limit.'));
                    }
                }
            }
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

apiClient.interceptors.response.use(
    (response) => {
        // Check for the custom token expiration message
        if (response.data && response.data.msg_status === '0002') {
            const authStore = useAuthStore();
            authStore.signOut(); // Clear the token and user data
            authStore.clearUser(); // Clear the token and user data

            showMessage('Session expired. Redirecting to homepage...', 'danger', 'top-end', true, '', 3000);
            setTimeout(() => {
                window.location.href = '/'; // Redirect to the homepage
            }, 3000); // Wait for the message to be displayed before redirecting

            return Promise.reject(new Error('Token expired'));
        }

        return response;
    },
    (error) => {
        const authStore = useAuthStore();
        console.log(error.response);

        // Check if the error response indicates token expiration
        if (error.response && error.response.status === 401) {
            authStore.signOut(); // Clear the token and user data
            authStore.clearUser(); // Clear the token and user data

            showMessage('Session expired. Redirecting to homepage...', 'danger', 'top-end', true, '', 3000);
            setTimeout(() => {
                window.location.href = '/'; // Redirect to the homepage
            }, 3000); // Wait for the message to be displayed before redirecting
        } else {
            showMessage('An error occurred. Please try again.', 'danger', 'top-end', true, '', 3000);
        }

        return Promise.reject(error);
    }
);

export const getAgencyList = async (params: {
    company_id?: number;
    country_id?: number;
    limit?: number;
    name?: string;
    offset?: number;
    state_id?: number;
}): Promise<any> => {
    try {
        const response = await apiClient.post('/agency_list', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching agency list:', error);
        throw error;
    }
};

export const getAgentList = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('agent_list', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};

export const getContactList = async (params: {
    company_id?: number;
    contact_id?: number;
    country_id?: number;
    limit?: number;
    mobile?: string;
    name?: string;
    offset?: number;
    phone?: string;
    state_id?: number;
}): Promise<any> => {
    try {
        const response = await apiClient.post('contact_list', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching contact list:', error);
        throw error;
    }
};

export const updateCrmLeadLost = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('crm_lead_lost', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const createLeadsExpense = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('create_lead_expenses', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const getUsersList = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('chat_user_list', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const getListCrmExpense = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('list_crm_lead_expenses', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const getListInvoiceLine  = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('invoice_line_list', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const getListInvoice = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('invoice_list', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const updatePartnerProfile = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('partner-profile-update', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const updatePartnerProfileImage = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('partner-image-upsert', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};

export const getListInvoicePayment = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('invoice_payment_list', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const deleteTasktodo = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('delete_task_todo', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const updateTasktodo = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('update_task_todo', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};

export const getPropertyPinList = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('property_pinned', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};

export const propertyFacility = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('property-facility-list', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const getGreting = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('dashboard_greet_customer', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};

export const geAccessRight = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('get-access-rights', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};

export const addUserCompany = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('register_company_user', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};


export const createProperty = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('property-create', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};

export const updateProperty = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('property-edit', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};

export const updateStatusProperty = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('property-update-status', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};

export const getContractList = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('contract-list', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const deleteContract = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('contract-delete', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const createContract = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('contract-create', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const updateContract = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('contract-edit', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const detailContract = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('contract-detail', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};

export const getContractListTemplate = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('contract-template-list', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const deleteContractTemplate = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('contract-template-delete', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};

export const detailContractTemplate = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('contract-template-detail', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};

export const createContractTemplate = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('contract-template-create', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const updateContractTemplate = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('contract-template-edit', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};

export const createPropertyImage = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('property-image-create', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};

export const createTaskToDo = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('create_task_todo', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const getTaksList = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('list_task_todo', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const getTaskStageList = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('task_stage_list', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const createNote = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('create_notes', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const removeNotes = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('delete_notes', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const getEmailList = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('mass-mailing-mail-list', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const getEmailDetail = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('mass-mailing-mail-detail', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const updateEmail = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('mass-mailing-mail-edit', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const actionSendEmail = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('mass-mailing-mail-action-send', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const actionCreatedEmail = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('mass-mailing-mail-add', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const crmStageCreate  = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('crm_stage_lead_list_create', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};

export const crmStageDelete  = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('crm_stage_lead_list_delete', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const crmStageUpdate = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('crm_stage_lead_list_update', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};

export const crmTransactionCreate = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('create_crm_transactions', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const crmTransactionList= async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('crm_transactions_list', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const crmTransactionDelete = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('delete_crm_transactions', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const crmTransactionUpdate = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('update_crm_transactions', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};

export const smartActionTypeList = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('mail_activity_type_list', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const smartActionCreate = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('create_mail_activity', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const smartActionDelete = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('delete_mail_activity', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const smartActionList = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('mail_activity_list', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const smartActionUpdate = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('update_mail_activity', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const createCrmLead = async (params: {
    company_id: number;
    customer_id: number;
    email_from: string;
    lead_id: number;
    limit?: number;
    name: string;
    offset?: number;
    phone?: string;
    partner_id?: number;
    tag_ids?: any;
    probability?: number;
    expected_revenue: number;
    user_id: number;
}): Promise<any> => {
    try {
        const response = await apiClient.post('create_crm_lead', params);
        return response.data;
    } catch (error) {
        console.error('Error creating CRM lead:', error);
        throw error;
    }
};

export const getCRMTagList = async (params: { limit?: number; name?: string; offset?: number; tag_id?: number }): Promise<any> => {
    try {
        const response = await apiClient.post('crm_tag_list', params);
        return response.data;
    } catch (error) {
        console.error('Error creating public CRM lead:', error);
        throw error;
    }
};

export const getCRMStageList = async (params: { limit?: number; name?: string; offset?: number; stage_id?: number }): Promise<any> => {
    try {
        const response = await apiClient.post('crm_stage_lead_list', params);
        return response.data;
    } catch (error) {
        console.error('Error creating public CRM lead:', error);
        throw error;
    }
};
export const createCrmLeadPublic = async (params: {
    company_id: number;
    customer_id: number;
    email_from: string;
    lead_id: number;
    limit?: number;
    name: string;
    offset?: number;
    phone?: string;
    user_id?: string;
}): Promise<any> => {
    try {
        const response = await apiClient.post('create_crm_lead_public', params);
        return response.data;
    } catch (error) {
        console.error('Error creating public CRM lead:', error);
        throw error;
    }
};

export const createCrmTeam = async (params: {
    active: boolean;
    company_id: number;
    description: string;
    invoiced_target: number;
    limit?: number;
    name: string;
    offset?: number;
    user_id: number;
}): Promise<any> => {
    try {
        const response = await apiClient.post('create_crm_team', params);
        return response.data;
    } catch (error) {
        console.error('Error creating CRM team:', error);
        throw error;
    }
};

export const deleteCrmTeam = async (params: { company_id: number; lead_id: string; offset?: number; user_id: number; partner_id: number }): Promise<any> => {
    try {
        const response = await apiClient.post('delete_crm_lead', params);
        return response.data;
    } catch (error) {
        console.error('Error creating CRM team:', error);
        throw error;
    }
};
export const getCrmLeadList = async (params: {
    company_id: number;
    customer_id: number;
    email_from: string;
    lead_id: number;
    limit?: number;
    name: string;
    offset?: number;
    phone?: string;
}): Promise<any> => {
    try {
        const response = await apiClient.post('crm_lead_list', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching CRM lead list:', error);
        throw error;
    }
};

export const getCrmTagList = async (params: { limit?: number; name?: string; offset?: number; tag_id?: number }): Promise<any> => {
    try {
        const response = await apiClient.post('crm_tag_list', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching CRM tag list:', error);
        throw error;
    }
};

export const getCountry = async (params: { param1: string }): Promise<any> => {
    try {
        const response = await apiClient.post('get-country', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching country information:', error);
        throw error;
    }
};

export const getProvince = async (params: { country_id: string }): Promise<any> => {
    try {
        const response = await apiClient.post('get-province', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching province information:', error);
        throw error;
    }
};

export const getCrmTeam = async (params: {
    company_id: number;
    limit?: number;
    name?: string;
    offset?: number;
    team_id: number;
    user_id: number;
}): Promise<any> => {
    try {
        const response = await apiClient.post('get_crm_team', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching CRM team:', error);
        throw error;
    }
};

export const getSaleSubscriptionPlan = async (params: {
    company_id: number;
    limit?: number;
    name?: string;
    offset?: number;
    plan_id: number;
}): Promise<any> => {
    try {
        const response = await apiClient.post('get_sale_subscription_plan', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching sale subscription plan:', error);
        throw error;
    }
};

export const getPartnerProfile = async (params: { Authorization: string; partner_id: number; user_id: number }): Promise<any> => {
    try {
        const response = await apiClient.post('partner-profile', params, {
            headers: { Authorization: params.Authorization },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching partner profile:', error);
        throw error;
    }
};

export const getProduct = async (params: { company_id: number; limit?: number; name?: string; offset?: number; product_id: number }): Promise<any> => {
    try {
        const response = await apiClient.post('product', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching product information:', error);
        throw error;
    }
};

export const registerCompany = async (params: {
    city: string;
    company_name: string;
    company_registry: string;
    country_id: number;
    date_register: string;
    email: string;
    is_trial_mode: boolean;
    mobile: string;
    password: string;
    phone: string;
    state_id: number;
    street: string;
    street2: string;
    vat: string;
    website: string;
    zip: string;
}): Promise<any> => {
    try {
        const response = await apiClient.post('register_company', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company:', error);
        throw error;
    }
};

export const registerCompanyUser = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('register_company_user', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};

export const signIn = async (params: { username: string; password: string }): Promise<any> => {
    try {
        const response = await apiClient.post('signin', params);
        return response.data;
    } catch (error) {
        console.error('Error signing in:', error);
        throw error;
    }
};

export const signOut = async (params: { company_id: number; partner_id: number }): Promise<any> => {
    try {
        const response = await apiClient.post('signout', params);
        return response.data;
    } catch (error) {
        console.error('Error signing out:', error);
        throw error;
    }
};

export const updateCrmLead = async (params: {
    company_id: number;
    customer_id: number;
    email_from: string;
    expected_revenue?: number;
    lead_id: number;
    limit?: number;
    name: string;
    offset?: number;
    phone?: string;
    probability?: number;
    stage_id?: number;
}): Promise<any> => {
    try {
        const response = await apiClient.post('update_crm_lead', params);
        return response.data;
    } catch (error) {
        console.error('Error updating CRM lead:', error);
        throw error;
    }
};

export const updateCrmTeam = async (params: {
    active: boolean;
    company_id: number;
    description: string;
    invoiced_target: number;
    limit?: number;
    name: string;
    offset?: number;
    user_id: number;
}): Promise<any> => {
    try {
        const response = await apiClient.post('update_crm_team', params);
        return response.data;
    } catch (error) {
        console.error('Error updating CRM team:', error);
        throw error;
    }
};

export const createSaleOrder = async (params: {
    client_order_ref: string;
    company_id: number;
    date_order: string;
    end_date: string;
    limit?: number;
    offset?: number;
    order_line: { product_id: number; qty: number }[];
    partner_id: number;
    payment_term_id: number;
    plan_id: number;
}): Promise<any> => {
    try {
        const response = await apiClient.post('create_sale_order', params);
        return response.data;
    } catch (error) {
        console.error('Error creating sale order:', error);
        throw error;
    }
};

export const getPaymentMethod = async (params: {
    company_id: number;
    limit?: number;
    offset?: number;
    name: string;
    midtrans_value: string;
    payment_method_id: number;
}): Promise<any> => {
    try {
        const response = await apiClient.post('payment_method', params);
        return response.data;
    } catch (error) {
        console.error('Error confirming sale order:', error);
        throw error;
    }
};

export const confirmSaleOrder = async (params: { company_id: number; limit?: number; offset?: number; sale_id: number }): Promise<any> => {
    try {
        const response = await apiClient.post('confirm_sale_order', params);
        return response.data;
    } catch (error) {
        console.error('Error confirming sale order:', error);
        throw error;
    }
};

export const createSaleOrderInvoice = async (params: { company_id: number; limit?: number; offset?: number; sale_id: number }): Promise<any> => {
    try {
        const response = await apiClient.post('sale_order_create_invoice', params);
        return response.data;
    } catch (error) {
        console.error('Error creating sale order:', error);
        throw error;
    }
};

export const confirmSaleOrderPayment = async (params: {
    company_id: number;
    limit?: number;
    offset?: number;
    invoice_id: number;
    midtrans_value: string;
    payment_method_id: number;
    sale_id: number;
    callback: string;
}): Promise<any> => {
    try {
        const response = await apiClient.post('sale_order_create_payment', params);
        return response.data;
    } catch (error) {
        console.error('Error confirming sale order:', error);
        throw error;
    }
};
export const confirmOrderPayment = async (params: {
    company_id: number;
    company_source_id: number;
    limit?: number;
    offset?: number;
    payment_id: number;
}): Promise<any> => {
    try {
        const response = await apiClient.post('sale_order_post_payment', params);
        return response.data;
    } catch (error) {
        console.error('Error confirming sale order:', error);
        throw error;
    }
};

export const getAssetList = async (params: { asset_id?: number; company_id?: number; limit?: number; name?: string; offset?: number }): Promise<any> => {
    try {
        const response = await apiClient.post('asset', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching asset list:', error);
        throw error;
    }
};

export const createAsset = async (params: {
    account_asset_id: number;
    account_depreciation_expense_id: number;
    account_depreciation_id: number;
    acquisition_date: string;
    already_depreciated_amount_import: number;
    company_id: number;
    journal_id: number;
    method: string;
    method_number: number;
    method_period: string;
    name: string;
    original_value: number;
    prorata_computation_type: string;
    prorata_date: string;
    salvage_value: number;
}): Promise<any> => {
    try {
        const response = await apiClient.post('asset-create', params);
        return response.data;
    } catch (error) {
        console.error('Error creating asset:', error);
        throw error;
    }
};

export const computeAssetDepreciation = async (params: { asset_id: number; company_id: number; date: string }): Promise<any> => {
    try {
        const response = await apiClient.post('asset-depreciation-compute', params);
        return response.data;
    } catch (error) {
        console.error('Error computing asset depreciation:', error);
        throw error;
    }
};

export const createContact = async (params: {
    company_id: number;
    contact_id?: number;
    country_id?: number;
    limit?: number;
    mobile?: string;
    name?: string;
    offset?: number;
    phone?: string;
    street?: string;
    street2?: string;
    state_id?: number;
}): Promise<any> => {
    try {
        const response = await apiClient.post('create_contact', params);
        return response.data;
    } catch (error) {
        console.error('Error creating contact:', error);
        throw error;
    }
};

export const updateContact = async (params: {
    company_id: number;
    contact_id: number;
    country_id?: number;
    limit?: number;
    mobile?: string;
    name?: string;
    offset?: number;
    phone?: string;
    state_id?: number;
}): Promise<any> => {
    try {
        const response = await apiClient.post('update_contact', params);
        return response.data;
    } catch (error) {
        console.error('Error updating contact:', error);
        throw error;
    }
};
export const deleteContact = async (params: { company_id: number; contact_id: number; limit?: number; offset?: number; partner_id: number }): Promise<any> => {
    try {
        const response = await apiClient.post('delete_contact', params);
        return response.data;
    } catch (error) {
        console.error('Error updating contact:', error);
        throw error;
    }
};

export const deleteAsset = async (params: { asset_id: number; company_id: number }): Promise<any> => {
    try {
        const response = await apiClient.post('asset-delete', params);
        return response.data;
    } catch (error) {
        console.error('Error deleting asset:', error);
        throw error;
    }
};

export const getAssetDetail = async (params: { asset_id: number; company_id: number }): Promise<any> => {
    try {
        const response = await apiClient.post('asset-detail', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching asset detail:', error);
        throw error;
    }
};

export const editAsset = async (params: {
    account_asset_id: number;
    account_depreciation_expense_id: number;
    account_depreciation_id: number;
    acquisition_date: string;
    already_depreciated_amount_import: number;
    asset_id: number;
    company_id: number;
    journal_id: number;
    method: string;
    method_number: number;
    method_period: string;
    name: string;
    original_value: number;
    prorata_computation_type: string;
    prorata_date: string;
    salvage_value: number;
}): Promise<any> => {
    try {
        const response = await apiClient.post('asset-edit', params);
        return response.data;
    } catch (error) {
        console.error('Error editing asset:', error);
        throw error;
    }
};

export const createCoA = async (params: { account_type: string; company_id: number; currency_id: number; name: string; reconcile: boolean }): Promise<any> => {
    try {
        const response = await apiClient.post('coa-create', params);
        return response.data;
    } catch (error) {
        console.error('Error creating CoA:', error);
        throw error;
    }
};

export const deleteCoA = async (params: { account_id: number; company_id: number }): Promise<any> => {
    try {
        const response = await apiClient.post('coa-delete', params);
        return response.data;
    } catch (error) {
        console.error('Error deleting CoA:', error);
        throw error;
    }
};

export const getCoADetail = async (params: { account_id: number; company_id: number }): Promise<any> => {
    try {
        const response = await apiClient.post('coa-detail', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching CoA detail:', error);
        throw error;
    }
};

export const editCoA = async (params: {
    account_id: number;
    account_type: string;
    company_id: number;
    currency_id: number;
    name: string;
    reconcile: boolean;
}): Promise<any> => {
    try {
        const response = await apiClient.post('coa-edit', params);
        return response.data;
    } catch (error) {
        console.error('Error editing CoA:', error);
        throw error;
    }
};

export const getCoAList = async (params: {
    account_types: string[];
    code: string;
    company_id: number;
    limit: number;
    name: string;
    offset: number;
}): Promise<any> => {
    try {
        const response = await apiClient.post('coa-list', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching CoA list:', error);
        throw error;
    }
};

export const createCalendarActivities = async (params: any) => {
    try {
        const response = await apiClient.post('create_calendar_activities', params);
        return response.data;
    } catch (error) {
        console.error('Error creating calendar activities:', error);
        throw error;
    }
};

export const createTaskTodo = async (params: any) => {
    try {
        const response = await apiClient.post('create_task_todo', params);
        return response.data;
    } catch (error) {
        console.error('Error creating task todo:', error);
        throw error;
    }
};

export const getCurrencyList = async (params: any) => {
    try {
        const response = await apiClient.post('currency-list', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching currency list:', error);
        throw error;
    }
};

export const getDashboardCrmRevenue = async (params: any) => {
    try {
        const response = await apiClient.get('dashboard_crm_revenue', { params });
        return response.data;
    } catch (error) {
        console.error('Error fetching CRM revenue:', error);
        throw error;
    }
};

export const suggestDashboardCrmRevenue = async (params: any) => {
    try {
        const response = await apiClient.post('dashboard_crm_suggest_revenue', params);
        return response.data;
    } catch (error) {
        console.error('Error suggesting CRM revenue:', error);
        throw error;
    }
};

export const deleteCalendarActivities = async (params: any) => {
    try {
        const response = await apiClient.post('delete_calendar_activities', params);
        return response.data;
    } catch (error) {
        console.error('Error deleting calendar activities:', error);
        throw error;
    }
};

export const deleteTaskTodo = async (params: any) => {
    try {
        const response = await apiClient.post('delete_task_todo', params);
        return response.data;
    } catch (error) {
        console.error('Error deleting task todo:', error);
        throw error;
    }
};

export const getEventList = async (params: any) => {
    try {
        const response = await apiClient.post('event_list', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching event list:', error);
        throw error;
    }
};

export const getEventStageList = async (params: any) => {
    try {
        const response = await apiClient.post('event_stage_list', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching event stage list:', error);
        throw error;
    }
};

export const getEventTagList = async (params: any) => {
    try {
        const response = await apiClient.post('event_tag_list', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching event tag list:', error);
        throw error;
    }
};

export const generateEmailAi = async (params: any) => {
    try {
        const response = await apiClient.post('generate_email_ai', params);
        return response.data;
    } catch (error) {
        console.error('Error generating email AI:', error);
        throw error;
    }
};

export const generateKeywords = async (params: any) => {
    try {
        const response = await apiClient.post('generate_keywords', params);
        return response.data;
    } catch (error) {
        console.error('Error generating keywords:', error);
        throw error;
    }
};

export const generatePostingAi = async (params: any) => {
    try {
        const response = await apiClient.post('generate_posting_ai', params);
        return response.data;
    } catch (error) {
        console.error('Error generating posting AI:', error);
        throw error;
    }
};

export const createJournal = async (params: any) => {
    try {
        const response = await apiClient.post('journal-create', params);
        return response.data;
    } catch (error) {
        console.error('Error creating journal:', error);
        throw error;
    }
};

export const deleteJournal = async (params: any) => {
    try {
        const response = await apiClient.post('journal-delete', params);
        return response.data;
    } catch (error) {
        console.error('Error deleting journal:', error);
        throw error;
    }
};

export const getJournalDetail = async (params: any) => {
    try {
        const response = await apiClient.post('journal-detail', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching journal detail:', error);
        throw error;
    }
};

export const editJournal = async (params: any) => {
    try {
        const response = await apiClient.post('journal-edit', params);
        return response.data;
    } catch (error) {
        console.error('Error editing journal:', error);
        throw error;
    }
};

export const getJournalList = async (params: any) => {
    try {
        const response = await apiClient.post('journal-list', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching journal list:', error);
        throw error;
    }
};

export const listCalendarActivities = async (params: any) => {
    try {
        const response = await apiClient.post('list_calendar_activities', params);
        return response.data;
    } catch (error) {
        console.error('Error listing calendar activities:', error);
        throw error;
    }
};

export const getListChat = async (params: any) => {
    try {
        const response = await apiClient.post('chat_room_list', params);
        return response.data;
    } catch (error) {
        console.error('Error listing calendar activities:', error);
        throw error;
    }
};

export const sendChat = async (params: any) => {
    try {
        const response = await apiClient.post('send_message', params);
        return response.data;
    } catch (error) {
        console.error('Error listing calendar activities:', error);
        throw error;
    }
};
export const socialMediaList = async (params: any) => {
    try {
        const response = await apiClient.post('social-media-list', params);
        return response.data;
    } catch (error) {
        console.error('Error listing calendar activities:', error);
        throw error;
    }
};
export const socialAccountList = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('social-account-list', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};

export const webTemplateList = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('webpage-template-list', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const createWebsite = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('webpage-create', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const getListWebsite = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('webpage-list', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};

export const webTemplateDetail = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('webpage-template-detail', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const getDetailDomain = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('get_detail_domains', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const createDomain = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('create_domain', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const getListDomain = async (params: any): Promise<any> => {
    try {
        const response = await apiClient.post('get_list_domains', params);
        return response.data;
    } catch (error) {
        console.error('Error registering company user:', error);
        throw error;
    }
};
export const socialMediaAdd = async (params: any) => {
    try {
        const response = await apiClient.post('social-media-add-account', params);
        return response.data;
    } catch (error) {
        console.error('Error listing calendar activities:', error);
        throw error;
    }
};
export const socialMediaValidate = async (params: any) => {
    try {
        const response = await apiClient.post('social-media-add-account-validate', params);
        return response.data;
    } catch (error) {
        console.error('Error listing calendar activities:', error);
        throw error;
    }
};

export const send_message_public = async (params: any) => {
    try {
        const response = await apiClient.post('send_message_public', params);
        return response.data;
    } catch (error) {
        console.error('Error listing calendar activities:', error);
        throw error;
    }
};
export const historyChat = async (params: any) => {
    try {
        const response = await apiClient.post('history_message', params);
        return response.data;
    } catch (error) {
        console.error('Error listing calendar activities:', error);
        throw error;
    }
};
export const developerList = async (params: any) => {
    try {
        const response = await apiClient.post('property-developer-list', params);
        return response.data;
    } catch (error) {
        console.error('Error listing calendar activities:', error);
        throw error;
    }
};

export const developerCreate = async (params: any) => {
    try {
        const response = await apiClient.post('property-developer-create', params);
        return response.data;
    } catch (error) {
        console.error('Error listing calendar activities:', error);
        throw error;
    }
};
export const developerUpdate = async (params: any) => {
    try {
        const response = await apiClient.post('property-developer-edit', params);
        return response.data;
    } catch (error) {
        console.error('Error listing calendar activities:', error);
        throw error;
    }
};

export const developerDelete = async (params: any) => {
    try {
        const response = await apiClient.post('property-developer-delete', params);
        return response.data;
    } catch (error) {
        console.error('Error listing calendar activities:', error);
        throw error;
    }
};

export const propertyPayMethodList = async (params: any) => {
    try {
        const response = await apiClient.post('property-payment-method-list', params);
        return response.data;
    } catch (error) {
        console.error('Error listing calendar activities:', error);
        throw error;
    }
};
export const propertyRegiondList = async (params: any) => {
    try {
        const response = await apiClient.post('property-region-list', params);
        return response.data;
    } catch (error) {
        console.error('Error listing calendar activities:', error);
        throw error;
    }
};

export const listTaskTodo = async (params: any) => {
    try {
        const response = await apiClient.post('list_task_todo', params);
        return response.data;
    } catch (error) {
        console.error('Error listing task todo:', error);
        throw error;
    }
};

export const checkPartnerOtp = async (params: any) => {
    try {
        const response = await apiClient.post('partner-check-otp', params);
        return response.data;
    } catch (error) {
        console.error('Error checking partner OTP:', error);
        throw error;
    }
};

export const forgetPartnerPassword = async (params: any) => {
    try {
        const response = await apiClient.post('partner-forget-password', params);
        return response.data;
    } catch (error) {
        console.error('Error forgetting partner password:', error);
        throw error;
    }
};

export const updatePartnerPublicPassword = async (params: any) => {
    try {
        const response = await apiClient.post('partner-public-update-password', params);
        return response.data;
    } catch (error) {
        console.error('Error updating partner public password:', error);
        throw error;
    }
};

export const updatePartnerPassword = async (params: any) => {
    try {
        const response = await apiClient.post('partner-update-password', params);
        return response.data;
    } catch (error) {
        console.error('Error updating partner password:', error);
        throw error;
    }
};

export const getPropertyList = async (params: any) => {
    try {
        const response = await apiClient.post('property-list', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching property list:', error);
        throw error;
    }
};

export const getPropertyDetail = async (params: any) => {
    try {
        const response = await apiClient.post('property-detail', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching property list:', error);
        throw error;
    }
};

export const generatePosting = async (params: any) => {
    try {
        const response = await apiClient.post('generate_posting_ai', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching property list:', error);
        throw error;
    }
};
export const deleteProperty = async (params: any) => {
    try {
        const response = await apiClient.post('property-delete', params);
        return response.data;
    } catch (error) {
        console.error('Error fetching property list:', error);
        throw error;
    }
};

export const updateCalendarActivities = async (params: any) => {
    try {
        const response = await apiClient.post('update_calendar_activities', params);
        return response.data;
    } catch (error) {
        console.error('Error updating calendar activities:', error);
        throw error;
    }
};

export const updateTaskTodo = async (params: any) => {
    try {
        const response = await apiClient.post('update_task_todo', params);
        return response.data;
    } catch (error) {
        console.error('Error updating task todo:', error);
        throw error;
    }
};
